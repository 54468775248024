export function calcWinningSquares(squares, boardSide, squareplayed) {
    const player = squares[squareplayed];
    const col = squareplayed % boardSide;
    const row = Math.trunc(squareplayed / boardSide);
    let winningDetails = null;
    let winningSquares = [];
    let winningLine;

    //  By using concat() in this way, we duplicate winning squares if there is more than one winning line
    //  Therefore, the winningSquares array is bigger than necessary due to these duplicates, but it probably is not significant at the moment
    winningLine = checkForWinningRow(player, row, squares, boardSide);
    if (winningLine) winningSquares = winningSquares.concat(winningLine);

    winningLine = checkForWinningCol(player, col, squares, boardSide);
    if (winningLine) winningSquares = winningSquares.concat(winningLine);

    winningLine = (row === col) ? checkForWinningForwardDiagonal(player, squares, boardSide) : null;
    if (winningLine) winningSquares = winningSquares.concat(winningLine);

    winningLine = (row === ((boardSide) - col - 1)) ? checkForWinningBackwardDiagonal(player, squares, boardSide) : null;
    if (winningLine) winningSquares = winningSquares.concat(winningLine);

    if (winningSquares.length > 0) {
        winningDetails = { winningLetter: player, winningLine: winningSquares };
    }

    return winningDetails;
}

export function checkForWinningRow(player, row, squares, boardSide) {
    let winningLine = []
    let i, l;
    for (i = row * boardSide, l = row * boardSide + boardSide; i < l; i++) {
        if (squares[i] === player) {
            winningLine.push(i);
        } else {
            return null;
        }
    }
    return winningLine;
}

export function checkForWinningCol(player, col, squares, boardSide) {
    let winningLine = [];
    let i, l;
    for (i = col, l = (boardSide * boardSide); i < l; i += boardSide) {
        if (squares[i] === player) {
            winningLine.push(i);
        } else {
            return null;
        }
    }
    return winningLine;
}

export function checkForWinningForwardDiagonal(player, squares, boardSide) {
    let winningLine = [];
    let i, l;
    for (i = 0, l = (boardSide * boardSide); i < l; i += boardSide + 1) {
        if (squares[i] === player) {
            winningLine.push(i);
        } else {
            return null;
        }
    }
    return winningLine;
}

export function checkForWinningBackwardDiagonal(player, squares, boardSide) {
    let winningLine = [];
    let i, l;
    for (i = boardSide - 1, l = (boardSide * (boardSide - 1) + 1); i < l; i += (boardSide - 1)) {
        if (squares[i] === player) {
            winningLine.push(i);
        } else {
            return null;
        }
    }
    return winningLine;
}