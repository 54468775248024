import React from "react";

import ReactDOM from "react-dom";
import "./index.css";

import { calcWinningSquares } from "./calculate-winner";

function Square(props) {
  let classSquare = props.isWinningSquare ? "square-winner" : "square";
  classSquare = props.lastMove ? classSquare + " square-selected" : classSquare;
  classSquare = classSquare + " " + props.squareSize;

  return (
    <button className={classSquare} onClick={props.onClick}>
      {props.value}
    </button>
  );
}

class Board extends React.Component {
  renderSquare(squareNumber) {
    let isWinningSquare = this.props.winningLine
      ? this.props.winningLine.includes(squareNumber)
      : false;

    return (
      <Square
        lastMove={this.props.move === squareNumber}
        key={squareNumber}
        isWinningSquare={isWinningSquare}
        value={this.props.squares[squareNumber]}
        onClick={() => this.props.onClick(squareNumber)}
        squareSize={
          this.props.boardSize >= 8
            ? "square-small"
            : this.props.boardSize >= 6
            ? "square-medium"
            : "square-big"
        }
      />
    );
  }

  render() {
    const board = [];
    let boardSize = this.props.boardSize;

    for (let rowNumber = 0; rowNumber < boardSize; rowNumber++) {
      const rows = [];
      for (let colNumber = 0; colNumber < boardSize; colNumber++) {
        rows.push(this.renderSquare(rowNumber * boardSize + colNumber));
      }
      board.push(
        <div key={rowNumber} className="board-row">
          {rows}
        </div>
      );
    }

    return <div>{board}</div>;
  }
}

class Game extends React.Component {
  constructor(props) {
    super();
    this.boardSize = 4;
    this.state = {
      history: [
        {
          squares: Array(this.boardSize * this.boardSize).fill(null),
          row: -1,
          col: -1,
          winnerDetails: null,
        },
      ],
      stepNumber: 0,
      xIsNext: true,
      isAscendingMoves: false,
    };

    this.changeBoardSize = this.changeBoardSize.bind(this);
  }

  toggleAscendingMoves() {
    this.setState({ isAscendingMoves: !this.state.isAscendingMoves });
  }

  changeBoardSize(event) {
    this.boardSize = Number(event.target.value);
    this.setState({
      history: [
        {
          squares: Array(this.boardSize * this.boardSize).fill(null),
          row: -1,
          col: -1,
          winnerDetails: null,
        },
      ],
      stepNumber: 0,
      xIsNext: true,
    });
  }

  handleClick(i) {
    const history = this.state.history.slice(0, this.state.stepNumber + 1);
    const current = history[history.length - 1];
    const squares = current.squares.slice();

    const row = Math.trunc(i / this.boardSize);
    const col = i % this.boardSize;

    //  return if the game has already been won or if there are no more moves to be made
    if (current.winnerDetails || squares[i] !== null) return;

    //  Update the squares[array] with the current move and then check to see if we have a winning line
    squares[i] = this.state.xIsNext ? "X" : "O";
    const winnerDetails = calcWinningSquares(squares, this.boardSize, i);
    this.setState({
      history: history.concat([
        {
          squares: squares,
          row: row,
          col: col,
          winnerDetails: winnerDetails,
        },
      ]),
      stepNumber: history.length,
      xIsNext: !this.state.xIsNext,
    });
  }

  jumpTo(step) {
    this.setState({
      stepNumber: step,
      xIsNext: step % 2 === 0,
    });
  }

  render() {
    const history = this.state.history;
    const current = history[this.state.stepNumber];
    const winnerDetails = current.winnerDetails;
    const boardLength = current.squares.length;

    let lastMove = null;

    const moves = history.map((step, move) => {
      if (this.state.stepNumber === move) {
        lastMove = history[move].row * this.boardSize + history[move].col;
      }
      const makeBold =
        this.state.stepNumber === move ? "jump-button-selected" : "jump-button";
      const desc = move
        ? "Go to move " +
          move +
          ", row: " +
          history[move].row +
          ", col: " +
          history[move].col
        : "Go to the game start";
      return (
        <li key={move.toString()}>
          <button className={makeBold} onClick={() => this.jumpTo(move)}>
            {desc}
          </button>
        </li>
      );
    });

    let status;
    let statusMovesMade;
    let winningLine = null;
    if (winnerDetails) {
      status = "Winner: " + winnerDetails.winningLetter;
      winningLine = winnerDetails.winningLine;
    } else if (this.state.stepNumber >= boardLength) {
      status = "Result: it is a draw!";
    } else {
      status = "Next player: " + (this.state.xIsNext ? "X" : "O");
    }
    statusMovesMade = " Moves made: " + this.state.stepNumber;

    if (this.state.isAscendingMoves) {
      moves.reverse();
    }

    const numbers = [3, 4, 5, 6, 7, 8, 9];
    const optionItems = numbers.map((number) => (
      <option key={number} value={number}>
        {number} x {number}
      </option>
    ));

    return (
      <div className="game">
        <div className="game-board-container">
          <div className="game-info">
            <label className="check-box-label">Select the board size: </label>
            <select value={this.boardSize} onChange={this.changeBoardSize}>
              {optionItems}
            </select>
            <div className="status-message">
              <div>{status}</div>
              <div>{statusMovesMade}</div>
            </div>
          </div>
        </div>
        <div className="game-board-container">
          <div className="game-board">
            <Board
              move={lastMove}
              boardSize={this.boardSize}
              winningLine={winningLine}
              squares={current.squares}
              onClick={(i) => this.handleClick(i)}
            />
          </div>
        </div>
        <div className="game-board-container">
          <div className="game-info">
            <input
              className="check-box"
              type="checkbox"
              id="isAscendingMoves"
              defaultChecked={this.state.isAscendingMoves}
              onChange={() => this.toggleAscendingMoves()}
            />
            <label className="check-box-label" htmlFor="isAscendingMoves">
              Show moves descending below
            </label>
            <div>
              <ol>{moves}</ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// ========================================

ReactDOM.render(<Game />, document.getElementById("root"));
